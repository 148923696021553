export default {
  items: [
    {
      name: 'Invoice Tools',
      url: '/collapse-default',
      icon: 'fa fa-tools',
      children : [
        {
          name: 'Invoice Search',
          url: '/wss',
          icon: 'fa fa-search',
        },
        {
          name: 'Master Invoice',
          url: '/wss/master-invoice',
          icon: 'fa fa-file-chart-line',
        },
        {
          name: 'Investigation Table',
          icon: 'fa fa-table',
          url: '/wss/investigation-table',
        },
      ]
    },
    {
      name: 'My Profile',
      url: '/wss/profile',
      icon: 'fa fa-user',
    },
  ]
}
