var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app" },
    [
      _c(
        "AppHeader",
        { attrs: { fixed: "" } },
        [
          _c("SidebarToggler", {
            staticClass: "d-lg-none",
            attrs: { display: "md", mobile: "" }
          }),
          _c("Logo"),
          _c("SidebarToggler", {
            staticClass: "d-md-down-none",
            attrs: { display: "lg" }
          }),
          _c("TopNavBar"),
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [_c("WssDropdownAccnt")],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "app-body" },
        [
          _c(
            "AppSidebar",
            { attrs: { fixed: "" } },
            [
              _c("SidebarHeader"),
              _c("SidebarForm"),
              _c("SidebarNav", { attrs: { navItems: _vm.nav } }),
              _c("SidebarFooter"),
              _c("SidebarMinimizer")
            ],
            1
          ),
          _c(
            "main",
            { staticClass: "main" },
            [
              _c("Breadcrumb", { attrs: { list: _vm.list } }),
              _c(
                "div",
                { staticClass: "container-fluid" },
                [
                  _c("flash-message", {
                    attrs: { autoHide: "", variant: "success" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "container-fluid" },
                [_c("router-view")],
                1
              )
            ],
            1
          ),
          _c("AppAside", { attrs: { fixed: "" } })
        ],
        1
      ),
      _c("TheFooter", [
        _c("div", { staticClass: "ml-auto" }, [
          _c("span", { staticClass: "mr-1" }, [_vm._v("Powered by")]),
          _c("a", { attrs: { href: "https://rtnest.ca" } }, [
            _vm._v("RTNest Inc. © " + _vm._s(_vm.CopyrightDate))
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }